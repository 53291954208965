.App {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  width: 100wh;
}

.App-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
}

.App-header {
  height: fit-content;
  width: 100%;
}

.App-content {
  flex-grow: 1;
  padding-top: 8px;
  padding-bottom: 16px;
}

.App-footer {
  flex-shrink: 1;
  width: 100%;
}
